import { RiArrowRightSLine } from "react-icons/ri";
import { Image } from "../assets";
import NavBar from "../components/reuseables/NavBar";
import AnimatedDiv from "../components/reuseables/AnimatedDiv";
import DefaultButton from "../components/reuseables/DefaultButton";
import Footer from "../components/reuseables/Footer";



const LandingPage = () => {
  const product: any = [
    {
      product: [
        "Payment",
        "Products",
        "Solutions",
        "SME ",
        "API Documentation",
      ],
      company: ["About us", "Careers", "Press", "News"],
      resources: ["Blog", "Events", "Help centre", "Support"],
      legal: ["Terms", "Privacy", "Settings", "Contact"],
      social: ["Twitter", "LinkedIn", "Facebook"],
    },
  ];


  const smartWay = [
    "Fully automated payment",
    "Quick response support",
    "Quick response support",
  ];
  const payroll = [
    {
      image:Image.mask,
      subdesc1:"PayAccess Gateway",
      subdesc2:" Your gateway to seamless, secure, and innovative payment solutions. At PayAccess, we understand that in today's fast-paced digital world, efficient and reliable payment processing is essential for businesses to thrive."
    },
    {
      image:Image.femaleSitting,
      subdesc1:"PayAccess POS",
      subdesc2:"Where every transaction becomes a seamless experience. Designed with efficiency and innovation in mind, We empower businesses of all sizes to elevate their customer service and operational efficiency. From retail to hospitality, ensuring quick and secure transactions with every swipe."
    },
      {
      image:Image.womanWorking,
      subdesc1:"PayAccess SuperAgent",
      subdesc2:"Your gateway to unparalleled financial empowerment. Whether you're an entrepreneur, a business owner, or an aspiring financial service provider, Payaccess SuperAgent is your trusted partner in expanding access to essential financial services. "
  }   
  ];
  const solution = [
    {
      title: "Accept online payment",
      descrp:
        "Easily receive money from anyone, anywhere in the world via our multitude of secure payment methods.",
        
    },
    {
      title: "Pos system",
      descrp:
        "Join the thousands who have embraced innovation, embraced growth, and embraced the future with our exceptional POS system.",
    },
    {
      title: "Withdraw",
      descrp:
        "Instant Withdrawal of funds to any account , anywhere in the world.",
    },
    {
      title: "Payment Links",
      descrp:
        "Create payment links to recieve payments from anywhere in the world",
    },
  ];
  return (
    <div>
      <NavBar/>

      <main>
        <AnimatedDiv>
          <div
            className="bg-no-repeat bg-cover min-h-[50vh] lg:min-h-[83vh]  lg:py-[8%] px-[7%] py-[20vh]"
            style={{ backgroundImage: `url(${Image.homepageBanner})` }}
          >
            <div className="md:w-1/2">
              <h1 className="text-[2rem] lg:text-[64px] font-bold">
                Get payment for all{" "}
                <span className="text-primary m-0 p-0 ">transaction</span> with
                one <span className="text-primary m-0 p-0 relative" >
                  <img src={Image.circle} alt="circle" className="absolute top-1 right-0 w-[30vw] md:h-[100px]" /> click</span>{" "}
              </h1>
              <p className="text-[#222222] text-[14px]">
              Empowering Your Transactions with Innovative, Secure, and Streamlined Payment and Collection Solutions.
              </p>

              <DefaultButton
                title="Get Started"
                className="!w-fit !px-[20px]"
              />
            </div>
          </div>
        </AnimatedDiv>

        {/* <AnimatedDiv> */}
          <div className="bg-[#5A99DC0F] bg-opacity-[6%] py-[55px] px-[7%] grid gap-[56px]">
            <div className="text-center w-3/4 m-auto grid gap-[10px]">
              <p className="text-[#07222D] font-bold text-[40px]">
                Unique solutions for for payments and collections
              </p>
              <p className="text-[18px] text-[#52525B] ">
              Tailored to Meet Your Financial Needs with Precision and Efficiency.
              </p>
            </div>
            <div className="flex gap-[30px] items-center relative  flex-wrap">
              {payroll.map((val, index) => (
                 <AnimatedDiv>

                <div
                  className="rounded-lg grid grid-rows-[2fr_3fr] max-w-[371px] m-auto"
                  key={index}
                >
                  <div className="p-[16px] grid bg-[#EEEEF0] rounded-t-lg">
                    <p className="font-bold text-[20px] text-[#0C394B]">
                      {
                        val?.subdesc1
                      }
                    </p>
                    <p className="text-[#0C394B] text-[14px]">
                   {
                    val?.subdesc2
                   }
                         </p>
                    <button className="text-white px-[10px] py-[5px] rounded-lg flex items-center justify-between text-[16px] bg-[#2682A1] w-fit mt-[20px]">
                      <p>Get started</p>
                      <RiArrowRightSLine />
                    </button>
                  </div>
                  <img
                    src={val.image}
                    alt="woman working"
                    className="object-cover w-full"
                  />
                </div>
                 </AnimatedDiv>
              ))}
            </div>
          </div>
        {/* </AnimatedDiv> */}

        <AnimatedDiv>
          <div
            className="bg-[#FDD1C71F] bg-opacity-[12%] px-[7%] py-[50px] min-h-[100vh]"
            style={{ backgroundImage: `url(${Image.mainbg})` }}
          >
            <div className="text-center w-3/4 m-auto grid gap-[10px]">
              <p className="text-[#07222D] font-bold text-[40px]">
                We offer endless enterprise solution.
              </p>
              <p className="text-[18px] text-[#52525B] ">
              Tailored Strategies to Propel Your Enterprise Forward.
              </p>
            </div>
              <div className="grid gap-[20px] h-fit m-auto lg:w-2/3 md:grid-cols-[1fr_1fr] pt-[56px]">
                {solution.map((val, index) => (
                  <div
                    className="border-[1px] rounded-lg px-[15px] pb-[32px] py-[12px] grid gap-[20px] text-center
                    bg-gradient-to-r from-[#E1F4FC] via-[#F2FBFE] to-[#ffff]
                    "
                    key={index}
                  >
                    <img
                      src={Image.messageIcon}
                      alt="message icon"
                      className="m-auto"
                    />
                    <p className="font-bold text-[20px] text-[#07222D]">
                      {val.title}
                    </p>
                    <p className="text-[#2A2A2A] text-[14px]">{val.descrp}</p>
                  </div>
                ))}
              </div>
          </div>
        </AnimatedDiv>

          <div className="px-[7%] py-[50px] grid gap-[20px]">
        <AnimatedDiv>
            <div className="text-center w-3/4 m-auto grid gap-[10px]">
              <p className="text-[#07222D] font-bold text-[40px]">
                We offer endless SME solutions
              </p>
              <p className="text-[18px] text-[#52525B] ">
              Empowering Small Businesses with Customized Solutions for Growth and Success
              </p>
            </div>
            </AnimatedDiv>
            <AnimatedDiv>

            <div className="grid md:flex gap-[20px] items-center">
              <div className="md:w-1/2 grid gap-[20px]">
                <p className="text-[#0C394B] font-bold text-[40px]">
                  Online checkout
                </p>
                <p className="text-black font-semibold text-[16px]">
                  for small and medium business
                </p>
                <p className="text-[#1A1A1A] text-[14px] ">
                Welcome to the future of seamless transactions! Our online checkout solution is tailor-made for small and medium businesses, ensuring you can focus on what you do best while we handle the rest. With user-friendly interfaces and robust security features, we make it effortless for your customers to complete purchases online, driving growth and customer satisfaction. Streamline your operations and enhance your digital presence with our trusted online checkout platform, designed to elevate your business to new heights.
                </p>
                <p className="text-[#23AAE1] text-[14px] flex items-center gap-[10px] cursor-pointer">
                  Learn More
                  <RiArrowRightSLine />
                </p>
              </div>
              <img
                src={Image.phone}
                alt="phone"
                className="rounded-md object-cover  md:w-[60%]"
              />
            </div>
        </AnimatedDiv>
        <AnimatedDiv>

            <div className="grid md:flex gap-[20px] items-center">
              <img
                src={Image.pos}
                alt="phone"
                className="rounded-md object-cover  md:w-[60%]"
              />
              <div className="md:w-1/2 grid gap-[20px]">
                <p className="text-[#0C394B] font-bold text-[40px]">
                Superior Devices
                </p>
                <p className="text-black font-semibold text-[16px]">
                  for small and medium business
                </p>
                <p className="text-[#1A1A1A] text-[14px] ">
                Imagine a world where technology seamlessly integrates into every aspect of your day. Superior devices are not just tools; they're companions in efficiency, reliability, and innovation. From sleek, powerful pos devices that anticipate your needs to cutting-edge features that empower your creativity, our pos devices redefine what's possible.
                </p>
                <p className="text-[#23AAE1] text-[14px] flex items-center gap-[10px] cursor-pointer">
                  Learn More
                  <RiArrowRightSLine />
                </p>
              </div>
            </div>
        </AnimatedDiv>
        <AnimatedDiv>

            <div className="grid md:flex gap-[20px] items-center">
              <div className="md:w-1/2 grid gap-[20px]">
                <p className="text-[#0C394B] font-bold text-[40px]">
                  Card Transactions and Processing
                </p>
                <p className="text-black font-semibold text-[16px]">
                  for small and medium business
                </p>
                <p className="text-[#1A1A1A] text-[14px] ">
                Imagine stepping into a world where convenience meets security at every payment. Our Card transactions effortlessly bridge the gap between consumers and merchants, enabling purchases that are swift, secure, and reliable. They empower businesses large and small, from bustling urban storefronts to quaint online boutiques, to cater to customers with ease.
                </p>
                <p className="text-[#23AAE1] text-[14px] flex items-center gap-[10px] cursor-pointer">
                  Learn More
                  <RiArrowRightSLine />
                </p>
              </div>
              <img
                src={Image.young}
                alt="phone"
                className="rounded-md object-cover md:w-[60%] "
              />
            </div>
        </AnimatedDiv>

          </div>

        <AnimatedDiv>
          <div className=" bg-gradient-to-r from-[#FDFDFD8A] from-opacity-[54%]  to-[#D7E2E6] to-opacity-[0%] grid gap-[50px] py-[50px]">
            <div className="text-center w-2/3 lg:w-1/2  m-auto grid gap-[10px]">
              <p className="text-[#07222D] font-bold text-[40px]">
                Make Seamless Business Decisions With Our Robust Reporting Feature
              </p>
              <p className="text-[18px] text-[#52525B] ">
              Insightful Data Analysis for Informed Decision-Making
              </p>
            </div>

            <img
              src={Image.freelance}
              alt="woman in laptop"
              className="m-auto md:w-3/4 object-cover"
            />
          </div>
        </AnimatedDiv>

        <AnimatedDiv>
          <div className="bg-[rgba(239, 251, 255, 0.14)] px-[7%] py-[50px] ">
            <div className="grid md:flex gap-[20px] items-center">
              <div className="md:w-1/2 grid gap-[20px]">
                <p className="text-[#222222] text-[14px] font-bold">
                  Designed for Businesses, Merchants and Individuals 
                </p>
                <p className="text-[#07222D] text-[24px] lg:text-[48px] font-bold">
                  The smart way to receiving instant payment
                </p>
                <p className="text-[#07222D] text-[14px] ">
                  
                </p>

                <div className="grid gap-[20px]">
                  {smartWay.map((val, index) => (
                    <div className="flex items-center gap-[30px]" key={index}>
                      <img src={Image.star} alt="star" />
                      <p className="text-[14px] lg:text-[24px] text-black font-bold">
                        {val}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
              <img
                src={Image.thoughtfulWoman}
                alt="phone"
                className="rounded-md object-cover   md:w-[60%]"
              />
            </div>
          </div>
        </AnimatedDiv>

        <AnimatedDiv>
          <div className="px-[7%] text-center py-[50px] grid gap-[100px]">
            <div className="grid gap-[30px]">
              <p className="text-[#475569] font-bold text-[20px]">
                Join over 200+ companies already using Payaccess business
              </p>
              <div className=" m-auto flex items-center  gap-y-[10px] flex-wrap justify-center">
                <img src={Image.waverio} alt="global services" />
                <img src={Image.logp} alt="logp" />
                <img src={Image.alterbone} alt="alterbone" />
                <img src={Image.carbonia} alt="carbonia" />
              </div>
            </div>
            <div className="md:w-3/4 rounded-lg bg-[#CDE6F08F] bg-opacity-[56%] p-[32px] grid gap-[30px] m-auto">
              <img src={Image.avatarGroup} alt="people" className="m-auto" />
              <div className="grid gap-[8px] ">
                <p className="text-[#005677] text-[20px] font-semibold">
                  Join over 20 people currently using Payaccess
                </p>
                <p className="text-[#667085] text-[14px]">
                  Can’t find the answer you’re looking for? Please chat to our
                  friendly team.
                </p>
              </div>
              <DefaultButton
                title="Get Started"
                className="!w-fit !m-auto !px-[40px]"
              />
            </div>
          </div>
        </AnimatedDiv>
      </main>

      <Footer/>
    </div>
  );
};

export default LandingPage;
