import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Image } from "../assets";
import AnimatedDiv from "../components/reuseables/AnimatedDiv";
import DefaultButton from "../components/reuseables/DefaultButton";
import Footer from "../components/reuseables/Footer";
import NavBar from "../components/reuseables/NavBar";


const Products = () => {
  const enterpriseproduct = [
    {
      title: "PayAccess Payment Gateway",
      text: "Welcome to PayAccess, your ultimate solution for seamless and secure payment processing. Our state-of-the-art payment gateway is designed to cater to businesses of all sizes, ensuring fast, reliable, and efficient transactions. Whether you're running a small online store or a large enterprise, PayAccess provides the tools you need to manage your payments effortlessly. At PayAccess, we understand the complexities of payment processing and are committed to simplifying it for you. Our gateway is user-friendly, with an intuitive interface that makes managing payments a breeze. Our dedicated support team is available 24/7 to assist you with any queries or issues.",
      image: Image.gateway,
      className: "bg-gradient-to-b from-[#09E1FF14] via-[#09E1FF14] to-[#7E959914] bg-opacity-[8%]",
    },
    {
      title: "PayAccess Mobile Wallet",
      text: "Welcome to PayAccess Mobile Wallet, your one-stop solution for seamless, secure, and convenient digital transactions. Our mobile wallet is designed to provide you with the freedom to manage your finances anytime, anywhere, directly from your smartphone. With PayAccess Mobile Wallet, you can make instant payments, transfer funds, and access a variety of financial services with just a few taps.",
      image: Image.mobilewallet,
      className: "bg-[#F5F4F6]",
    },
    {
      title: "PayAccess Point-of-Sale(POS)",
      text: "Welcome to PayAccess Point-of-Sale (POS), the ultimate solution for businesses seeking efficient, reliable, and versatile payment processing. Our POS system is designed to streamline your transactions, enhance customer experience, and provide you with comprehensive sales data to help grow your business. With PayAccess POS, managing sales has never been easier.",
      image: Image.pointofsale,
      className: "bg-gradient-to-tr from-[#FFFFFF] via-[#FFFFFF] to-[#FFB272] bg-opacity-[100%]",
    },
    {
      title: "PayAccess Agency Banking Services",
      text: "Welcome to PayAccess Agency Banking Services, the cutting-edge solution designed to extend banking services to underserved and unbanked populations. Our platform empowers agents to provide essential financial services, bridging the gap between traditional banking institutions and communities in need. With PayAccess, financial inclusion is just a transaction away.",
      image: Image.agency,
      className: "bg-[#F6F4F4]",
    },
  ];

  // Array of refs for each enterprise product
  const refs:any = useRef([]);



  const ref1:any=useRef(null)
  const ref2:any=useRef(null)
  const ref3:any=useRef(null)
  const ref4:any=useRef(null)

  const location = useLocation();
  console.log(location)
  // Function to scroll to a specific ref based on location hash
  const scrollToRef = (targetIndex:any) => {
    let targetRef;
    switch (targetIndex) {
      case 1:
        targetRef = ref1;
        break;
      case 2:
        targetRef = ref2;
        break;
      case 3:
        targetRef = ref3;
        break;
      case 4:
        targetRef = ref4;
        break;
      default:
        targetRef = null;
        break;
    }

    if (targetRef && targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
        // Check location hash and scroll to corresponding ref
        switch (location.hash) {
          case "#payaccess-gateway":
            scrollToRef(1);
            break;
          case "#payaccess-mobile-wallet":
            scrollToRef(2);
            break;
          case "#payaccess-POS":
            scrollToRef(3);
            break;
    
          case "#payaccess-agency-banking":
            scrollToRef(4);
            break;
          // Add more cases if needed
          default:
            break;
        }
        
  }, [location, refs]);

  return (
    <div>
      <NavBar />
      {enterpriseproduct.map((val, index) => (
        <div
          className={` h-[90vh]  grid md:grid-cols-2 gap-y-[30px] gap-x-[15px] justify-between ${val.className}`}
          key={index}
          ref={(el) => {
            switch (index) {
              case 0:
                ref1.current = el;
                break;
              case 1:
                ref2.current = el;
                break;
              case 2:
                ref3.current = el;
                break;
              case 3:
                ref4.current = el;
                break;
              default:
                break;
            }
          }}
        >
          <div className="grid w-3/4 m-auto gap-[20px]">
            <h1 className="font-bold text-[60px]">{val.title}</h1>

            <p className="text-[#393B3C] text-[16px]">{val.text}</p>
            <div className="w-fit">
              <DefaultButton title="Learn more" />
            </div>
          </div>

          <img src={val.image} alt={val.title} className='m-auto rounded-lg'/>
        </div>
      ))}

      <AnimatedDiv>
        <div className="px-[7%] text-center py-[50px] grid gap-[100px]">
          <div className="grid gap-[30px]">
            <p className="text-[#475569] font-bold text-[20px]">
              Join over 200+ companies already using Payaccess business
            </p>
            <div className=" m-auto flex items-center gap-x-[50px] gap-y-[10px] flex-wrap justify-center">
              <img src={Image.waverio} alt="waverio" />
              <img src={Image.logp} alt="logp" />
              <img src={Image.alterbone} alt="alterbone" />
              <img src={Image.carbonia} alt="carbonia" />
              <img src={Image.preso} alt="preso" />
              <img src={Image.ridoria} alt="ridoria" />
              <img src={Image.incanto} alt="incanto" />
            </div>
          </div>
          <div className="md:w-3/4 rounded-lg bg-[#CDE6F08F] bg-opacity-[56%] p-[32px] grid gap-[30px] m-auto">
            <img src={Image.avatarGroup} alt="people" className="m-auto" />
            <div className="grid gap-[8px] ">
              <p className="text-[#005677] text-[20px] font-semibold">
                Join over 20 people currently using Payaccess
              </p>
              <p className="text-[#667085] text-[14px]">
                Can’t find the answer you’re looking for? Please chat to our
                friendly team.
              </p>
            </div>
            <DefaultButton
              title="Get Started"
              className="!w-fit !m-auto !px-[40px]"
            />
          </div>
        </div>
      </AnimatedDiv>
      <Footer/>
    </div>
  );
};

export default Products;
