import React from 'react'

const DefaultButton = ({ title, className,onClick,icon, isLoading,isDisabled}: any) => {
  return (
    <button
    className={`w-full rounded-lg bg-[#48B8E6] text-white py-[15px] px-[10px] mt-[20px] ${className} flex gap-2 items-center justify-center transition-all duration-500 hover:scale-105 text-[14px] hover:brightness-110 ${isDisabled && 'bg-opacity-[40%] cursor-not-allowed'}`}  onClick={onClick}
    disabled={isDisabled||false}
  >
    {icon}
    {isLoading?<div className="flex gap-2 items-center">
    {/* < /> */}
    <p>Loading...</p>
    </div>:title}
    {/* {disable} */}
  </button>
  )
}

export default DefaultButton