import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import "../Assets/styles/global.css";
import { CiSearch } from "react-icons/ci";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import NavBar from "../components/reuseables/NavBar";
import DefaultButton from "../components/reuseables/DefaultButton";
import Footer from "../components/reuseables/Footer";


const FAQs = () => {
  const [show, setShow] = useState(true);
  const [customer, setCustomer] = useState(false);
  const [pos, setPos] = useState(false);
  const [start, setStart] = useState(false);
  const [price, setPrice] = useState(false);
  const [password, setPassword] = useState(false);
  const [open, setOpen] = useState(1);

  const handleOpen = (value: any) => {
    setOpen(open === value ? 0 : value);
  };

  const customAnimation = {
    mount: { scale: 1 },
    unmount: { scale: 0.9 },
  };
  const navigate = useNavigate();
  const faqs:any = [
    {
      question: "How can I accept online payments with your service?",
      answer:
        "With our online payment solution, you can easily receive money from anyone, anywhere in the world via our multitude of secure payment methods. Whether you're selling products online or collecting donations, our platform makes it simple and convenient to accept payments."
    },
    {
      question: "What features does your POS system offer?",
      answer:
        "Our POS system is designed to streamline your business operations and enhance the customer experience. With features such as inventory management, sales reporting, and customer relationship management, you can efficiently manage your transactions and grow your business. Additionally, our POS terminals support various payment methods, including credit cards, debit cards, and mobile wallets, to accommodate your customers' preferences."
    },
    {
      question: "How can I withdraw funds from my account?",
      answer:
        "With our withdrawal service, you can easily access your funds whenever you need them. Whether you're withdrawing cash from an ATM, transferring funds to your bank account, or receiving a payout via mobile money, we provide flexible and convenient options to suit your needs. Our secure withdrawal process ensures that your funds are transferred quickly and safely, giving you peace of mind."
    },
    {
      question: "What is a merchant ID and how do I obtain one?",
      answer:
        "A merchant ID (MID) is a unique identifier assigned to merchants by payment processors to facilitate transactions. It allows merchants to accept card payments and track their sales activity. To obtain a merchant ID with us, simply sign up for our payment processing services and complete the necessary verification process. Once approved, you'll receive your merchant ID along with access to our suite of payment solutions."
    }
  ];
  
  return (
    <div>
      <NavBar />
      <div className="space-y-10 mt-[10em] ">
        <h1 className="text-[50px] font-bold text-center text-secondary">
          Frequently asked questions
        </h1>
        <div className="w-[50%]  md:w-[30%] grid justify-center m-auto">
          <input
            type="text"
            placeholder="Search your questions here"
            className="rounded-[5em] pl-[1em] border border-grey border-opacity-30 w-[300px] md:w-[518px] py-2 "
          />
          <CiSearch className="relative top-[-1.8em] left-[90%] " />
        </div>
      </div>
      
      <div className="max-w-[100em] m-auto mb-[5em]">
        <div className="flex  flex-col md:flex-row mx-[2em] mt-[10em] lg:mx-[10em] justify-between md:gap-[2em] space-y-[5em] md:space-y-0 ">
          <div className="space-y-5 md:w-3/4">
          <div className="mt-1 space-y-2 bg-white" id="accordion-open" data-accordion="open">
          {faqs.map((val:any,index:number) => (
        <>
          <h2 id="accordion-open-heading-1">
    <button type="button" className="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 rounded-t-xl focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-open-body-1" aria-expanded="true" aria-controls="accordion-open-body-1">
      <span className="flex items-center"><svg className="w-5 h-5 me-2 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd"></path></svg> {val.question}</span>
      <svg data-accordion-icon className="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
      </svg>
    </button>
  </h2>
  <div id="accordion-open-body-1" className="hidden" aria-labelledby="accordion-open-heading-1">
    <div className="p-5 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900">
      <p className="mb-2 text-gray-500 dark:text-gray-400">{val.answer}</p>
      
    </div>
  </div>
        </>
      ))}
        {/* {faqs.map((val:any,index:number) => (
          <Accordion
            key={index}
            open={open === index}
            animate={customAnimation}
            className="px-5 py-1 border border-grey border-opacity-30 rounded-sm bg-white" placeholder='' onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}   >
            <AccordionHeader onClick={() => handleOpen(index)} className="bg-white" placeholder='' onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}   >
              <p className="p-3 text-secondary">
                {val.question}
              </p>
            </AccordionHeader>

            <AccordionBody className={open !== index ? 'hidden' : 'bg-white'}>
              <p>
                {val.answer}
              </p>
            </AccordionBody>
          </Accordion>
        ))} */}
      </div>

           

            
          </div>

          <div className="border border-grey border-opacity-30 grid px-4 space-y-5 md:w-[30%] max-h-[500px] lg:h-[30%] py-10 rounded-lg">
            <img
              src="https://res.cloudinary.com/dewopisee/image/upload/v1682599551/Mail_i2tale.png"
              alt="mail"
              className="m-auto"
            />
            <div className="text-center">
              <h1>Do you have more questions?</h1>
              <p>
                End-to-end payments and business management in a single
                solution. Meet the right platform to help realize your business
                dreams.
              </p>
            </div>
            <div className="grid w-full">
              <DefaultButton
                onClick="mailTo:"
                title="Shoot a Direct Mail"
                className=" grid justify-center mb-[2em] text-center"
              />
            </div>
          </div>
        </div>
      </div>

      
      <Footer />
    </div>
  );
};

export default FAQs;
