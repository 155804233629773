import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Image } from "../assets";
import AnimatedDiv from "../components/reuseables/AnimatedDiv";
import DefaultButton from "../components/reuseables/DefaultButton";
import Footer from "../components/reuseables/Footer";
import NavBar from "../components/reuseables/NavBar";


const PaymentSolution = () => {
    const paymentproduct = [
        {
          title: "Online Checkout",
          text: "Experience the ease and efficiency of PayAccess Online Checkout, a seamless solution designed to streamline the payment process for small and medium businesses. Our platform ensures a smooth and secure transaction experience for your customers, enhancing their shopping journey and boosting your sales.",
          image: Image.gateway,
          className: "bg-gradient-to-b from-[#09E1FF14] via-[#09E1FF14] to-[#7E959914] bg-opacity-[8%]",
        },
        {
          title: "Payment Links",
          text: "Simplify the way you get paid with PayAccess Payment Links. Designed for businesses of all sizes, our payment links offer a convenient and efficient method to request payments from your customers, no matter where they are. Whether you're sending an invoice, requesting a deposit, or collecting payments for an event, PayAccess Payment Links make the process seamless and secure.",
          image: Image.mobilewallet,
          className: "bg-[#F5F4F6]",
        },
        {
          title: "Virtual Terminals",
          text: "Experience the ultimate in payment flexibility with PayAccess Virtual Terminals. Designed to cater to businesses that require a versatile, remote payment solution, our Virtual Terminals empower you to process transactions securely from any device with internet access. Whether you're taking orders over the phone, via email, or in-person without a physical card reader, PayAccess Virtual Terminals provide the perfect solution.",
          image: Image.pointofsale,
          className: "bg-gradient-to-b from-[#FFFFFF] via-[#FFFFFF] to-[#FFB272] bg-opacity-[100%]",
        },
      ];
    
      // Array of refs for each enterprise product
      const refs:any = useRef([]);
    
    
    
      const ref1:any=useRef(null)
      const ref2:any=useRef(null)
      const ref3:any=useRef(null)
      const ref4:any=useRef(null)
    
      const location = useLocation();
      console.log(location)
      // Function to scroll to a specific ref based on location hash
      const scrollToRef = (targetIndex:any) => {
        let targetRef;
        switch (targetIndex) {
          case 1:
            targetRef = ref1;
            break;
          case 2:
            targetRef = ref2;
            break;
          case 3:
            targetRef = ref3;
            break;
          default:
            targetRef = null;
            break;
        }
    
        if (targetRef && targetRef.current) {
          targetRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      };
    
      useEffect(() => {
            // Check location hash and scroll to corresponding ref
            switch (location.hash) {
              case "#onlineCheckout":
                scrollToRef(1);
                break;
              case "#paymentLinks":
                scrollToRef(2);
                break;
              case "#virtualTerminals":
                scrollToRef(3);
                break;
              // Add more cases if needed
              default:
                break;
            }
            
      }, [location, refs]);
    
      return (
        <div>
          <NavBar />
          {paymentproduct.map((val, index) => (
            <div
              className={`min-h-[90vh] pb-[20px]  grid md:grid-cols-2 gap-y-[30px] gap-x-[15px] justify-between ${val.className}`}
              key={index}
              ref={(el) => {
                switch (index) {
                  case 0:
                    ref1.current = el;
                    break;
                  case 1:
                    ref2.current = el;
                    break;
                  case 2:
                    ref3.current = el;
                    break;
                  case 3:
                    ref4.current = el;
                    break;
                  default:
                    break;
                }
              }}
            >
              <img src={val.image} alt={val.title} className='m-auto rounded-lg'/>
              <div className="grid w-3/4 m-auto gap-[20px]">
                <h1 className="font-bold text-[60px]">{val.title}</h1>
    
                <p className="text-[#393B3C] text-[16px]">{val.text}</p>
                <div className="w-fit">
                  <DefaultButton title="Learn more" />
                </div>
              </div>
            </div>
          ))}
    
          <AnimatedDiv>
            <div className="px-[7%] text-center py-[50px] grid gap-[100px]">
              <div className="grid gap-[30px]">
                <p className="text-[#475569] font-bold text-[20px]">
                  Join over 200+ companies already using Payaccess business
                </p>
                <div className=" m-auto flex items-center gap-x-[50px] gap-y-[10px] flex-wrap justify-center">
                  <img src={Image.waverio} alt="waverio" />
                  <img src={Image.logp} alt="logp" />
                  <img src={Image.alterbone} alt="alterbone" />
                  <img src={Image.carbonia} alt="carbonia" />
                  <img src={Image.preso} alt="preso" />
                  <img src={Image.ridoria} alt="ridoria" />
                  <img src={Image.incanto} alt="incanto" />
                </div>
              </div>
              <div className="md:w-3/4 rounded-lg bg-[#CDE6F08F] bg-opacity-[56%] p-[32px] grid gap-[30px] m-auto">
                <img src={Image.avatarGroup} alt="people" className="m-auto" />
                <div className="grid gap-[8px] ">
                  <p className="text-[#005677] text-[20px] font-semibold">
                    Join over 20 people currently using Payaccess
                  </p>
                  <p className="text-[#667085] text-[14px]">
                    Can’t find the answer you’re looking for? Please chat to our
                    friendly team.
                  </p>
                </div>
                <DefaultButton
                  title="Get Started"
                  className="!w-fit !m-auto !px-[40px]"
                />
              </div>
            </div>
          </AnimatedDiv>
          <Footer />
        </div>
      );
}

export default PaymentSolution