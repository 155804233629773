import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import DefaultButton from "../components/reuseables/DefaultButton";
import DefaultInput from "../components/reuseables/DefaultInput";
import Footer from "../components/reuseables/Footer";
import NavBar from "../components/reuseables/NavBar";

const AboutMe = () => {
    const careersRef:any = useRef(null);
    const contactRef:any = useRef(null);
    const location = useLocation();

  useEffect(() => {
    if (location.hash === '#careers' && careersRef.current) {
      careersRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (location.hash === '#contact' && contactRef.current){
        contactRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [location]);
    const team=[{name:'Chukwuebuka Godson',position:' CTO'},{name:'Okwekwu Austin,',position:'Project Manager'},{name:'Encey Ekaette ',position:'COO'},{name:'Onotu Precious',position:'CMO'}]

    const services=['Payment gateway','Mobile wallet','Agency Banking','Virtual Account','POS','Other']
    const [state, setstate] = useState({recipient:'',subject:'',message:'',submittingError:false,errMessage:'',isSubmitting:false,name:'',email:'',number:''})
    const [isChecked, setisChecked] = useState('')
  
    const {recipient,subject,message,name,email,number}=state
  
    // useEffect(() => {
    //   window.location.reload(true);
    //   // Reset state or take other actions when the component mounts
    // }, []);
    const handleCheckboxChange = (value:any) => {
      setstate((prevState:any)=>({
        ...prevState,
        subject:value
      }));
      setisChecked(value)
    };
    const handleChange = (e:any) => {
      setstate({
        ...state,
        [e.target.name]: e.target.value,
        submittingError: false,
      });
    };
  
    const emailMessage=`Good day, here are my details : name: ${name}, Phone Number: ${number}. ${message}`
    function onSubmit() {
  
      return `mailto:${recipient}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(emailMessage)}`
    }
  return (
    <div>
      <NavBar />
      <div className="text-center h-[90vh] bg-secondary bg-opacity-10 grid">
        <div className="grid w-3/4 md:w-1/2 m-auto gap-[20px]">
          <h1 className="font-bold text-[50px]">
            Powering a new generation of businesses in Africa
          </h1>

          <p className="text-[20px]">
          At PayAccess, we are dedicated to fueling the growth and innovation of African enterprises. By providing cutting-edge financial solutions and technologies, we empower businesses to reach new heights. Our mission is to support entrepreneurs, SMEs, and large corporations alike in overcoming challenges, optimizing their operations, and expanding their horizons. Together, we are building a brighter future for the African business landscape.
          </p>
        </div>
      </div>
      <div className="grid p-[20px] mt-[40px] gap-[20px]">
        <div className="w-3/4 lg:w-1/3 m-auto text-center grid gap-[20px]">
          <p className="font-bold text-[30px]">The Team</p>

          <p className="text-[20px]">
            We’re a diverse, close-knit team on an adventure to build something
            enduring, while learning something new, every day.
          </p>
        </div>

        <div className="grid md:grid-cols-4 grid-cols-2 gap-x-[30px] gap-y-[50px] mt-[40px]">
            {team.map((val,index)=>(
          <div className="grid gap-[20px] h-fit text-center " key={index}>
            <div className="h-[100px] w-[100px] rounded-full bg-grey bg-opacity-30 m-auto"></div>
            <div className="grid gap-1">
              <p className="font-bold text-[20px]">{val.name}</p>
              <p className="text-[15px]">{val.position}</p>
            </div>
          </div>

            ))}
        </div>
      </div>

      <div className="grid my-[40px] px-[7%]">
        <div className="grid gap-[10px] md:w-1/2">
        <p className="font-bold text-[40px]">Our Values</p>
        <p className=" text-[20px]">At PayAccess, our mission is to empower individuals and communities by providing accessible financial services tailored to their needs. We strive to foster financial inclusion, promote entrepreneurship, and create lasting social impact.</p>

        </div>

        {/* <div className="grid text-[15px] gap-[10px]">
            <ul className="list-disc">
                <li>Expand our reach to underserved populations, ensuring that everyone has access to affordable financial services regardless of their socio-economic status.</li>
                <li> Continuously improve our products and services to meet the evolving needs of our clients, fostering trust, loyalty, and satisfaction among our customer base.</li>
                <li>Implement robust risk management strategies to safeguard the interests of our clients and stakeholders while maintaining financial stability and sustainability.</li>
                <li>Foster a culture of innovation and technological advancement to enhance efficiency, streamline processes, and deliver innovative financial solutions that address the changing needs of our clients.</li>
            </ul>
        </div> */}


      </div>

      <div className="px-[7%] h-[100vh] bg-white grid" id='careers' ref={careersRef}>
      <div className="grid gap-[10px] md:w-1/2 my-auto">
        <p className="font-bold text-[40px]">Come work with us</p>
        <p className=" text-[20px]">Come do meaningful work with kind, smart colleagues who're invested in your growth</p>

        <p className="text-[15px] mt-[10px] text-secondary cursor-pointer
        ">See all open positions</p>

        </div>
      </div>

      <div className='grid md:grid-cols-2  gap-[30px] border-t-[1px] items-center mb-[50px]' ref={contactRef} id='contact'>
          <div className="rounded-md">
            <img src="https://images.unsplash.com/photo-1665686306574-1ace09918530?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fGJ1c2luZXNzfGVufDB8fDB8fHww" alt=" "  className='rounded-md object-cover lg:h-[120vh] md:w-[50vw]'/>
          </div>
            <div className='w-full grid gap-[15px] px-10'>
              <div className="grid gap-[20px]">
                <p className="text-[46px] font-bold font-[#101828]">Contact us</p>
                <p className="text-[20px]">You can reach us anytime via <span className="text-[#3477E4]">email</span> </p>
              </div>
            <DefaultInput label='Name' placeHolder='Your name' value={name} name='name' handleChange={handleChange}/>
            <DefaultInput label='Email' placeHolder='you@company.com' value={email} name='email' handleChange={handleChange}/>
                <DefaultInput label='Phone number' placeHolder='+1 (555) 000-0000' value={number} name='number' handleChange={handleChange}/>
                
                <div className="w-full grid gap-1">
                  <p className="text-[#344054]">How can we help?</p>
                <textarea className='border w-full border-white-50 rounded-lg placeholder:text-white-50 text-black p-[1em]' placeholder='Tell us a little about the project...' name="message" id="" cols={20} rows={5} value={message} onChange={handleChange}></textarea>
                </div>

                <div className="grid gap-[20px]">
                  <p className="text-[#344054] font-bold">Services</p>
                  <div className="grid grid-cols-2 gap-[15px]">
                    {services.map((val)=>(

                    <div className="flex gap-2 items-center h-fit">
                      <input type="checkbox" name="" id="" onChange={() => handleCheckboxChange(val)} checked={isChecked===val}/>
                      <p className="text-[#344054] text-[16px]">{val}</p>
                    </div>
                    ))}
                  </div>
                </div>

                    <DefaultButton title='Send Message' onClick={`mailto:${recipient}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(emailMessage)}`} className='text-center'/>
            </div>
        </div>
      <Footer />
    </div>
  );
};

export default AboutMe;
