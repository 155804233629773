import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AboutMe from "./Pages/AboutMe";
import Compliance from "./Pages/Compliance";
import FAQs from "./Pages/FAQs";
import LandingPage from "./Pages/Login";
import PaymentSolution from "./Pages/PaymentSolution";
import Products from "./Pages/Products";

function App() {
  return (
    <Router>
      <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/about" element={<AboutMe />} />
      <Route path="/compliance" element={<Compliance />} />
      <Route path="/FAQs" element={<FAQs />} />
      <Route path="/paymentSolution" element={<PaymentSolution />} />
      <Route path="/product" element={<Products />} />
    </Routes>
    </Router>
  );
}

export default App;
